import ReactDOM from 'react-dom/client';
import routes from './Components/Routes';
import {CLRouterProvider} from 'anthill-component-library/helpers/master'
import {AppContextProvider} from 'anthill-component-library/contexts/app'

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <AppContextProvider>
     <CLRouterProvider router={routes} />
  </AppContextProvider>
);
