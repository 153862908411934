import Dashboard from './Dashboard/Dashboard';

const mainRoutes = [
    {
        path: 'dashboard',
        element: <Dashboard />
    }
    
]

export default mainRoutes