import { RiHome2Line } from "react-icons/ri"
import {SidebarItem} from "anthill-component-library/layouts/master"

function NavItems() {


  return (
  <>
    <SidebarItem title='Dashboard' children={<RiHome2Line />} />
  </>
  )
}

export default NavItems